import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import addcustomerRuleMapping from './addcustomerRuleMapping';
export default {
  name: 'CustomerRuleMapping',
  components: {
    addcustomerRuleMapping
  },
  watch: {
    currentPage: function() {
      this.getDunningCustomerRuleMappingList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getDunningCustomerRuleMappingList();
    }
  },
  data() {
    return {
      dunningRuleMappingDetail: null,
      dunningLetterMasterId: null,
      showAddCustomerRuleMappingModal: false,
      customerName: null,
      erpCustomerNumber: null,
      customerShortName: null,

      active: false,
      mapLetter: {
        value: null,
        text: null
      },
      parent_value_set_id: null,
      showValueSetModal: false,

      payload: null,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      vsetCode: null,
      setTimeVsetCode: null,
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      periodType: null,
      OperatingUnitList: [
        {
          value: '2A',
          text: '2A'
        },
        {
          value: '2B',
          text: '2B'
        }
      ],
      dunningCustomerRuleMappingData: [],
      dunningCustomerRuleMappingFields: [
        {
          key: 'customer_id'
        },
        {
          key: 'erp_customer_id',
          label: 'ERP Customer Id'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'erp_customer_no',
          label: 'ERP Customer Number'
        },
        {
          key: 'short_name',
          label: 'Customer Short Name'
        },
        {
          key: 'mapped_rule_count',
          label: 'Mapped Rule Count'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddCustomerRuleMappingModal = false;
          this.dunningRuleMappingDetail = null;
          this.dunningLetterMasterId = null;
        }
      }
    });
  },
  methods: {
    getDunningCustomerRuleMappingList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        customer_name: this.customerName,
        erp_customer_no: this.erpCustomerNumber,
        short_name: this.customerShortName,
        is_rule_mapped: this.active
      };
      this.loader = true;
      this.$store
        .dispatch('dunningAdmin/getDunningCustomerRuleMappingList', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.dunningCustomerRuleMappingData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_RULE_LIST) {
        this.ruleMasterId = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_RULE_LIST) {
        this.mapLetter.text = item.value_code;
        this.mapLetter.value = item.value_set_dtl_id;
      }
      this.showValueSetModal = false;
    },

    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },

    closeValueSetModal() {
      this.showValueSetModal = false;
    },

    clear() {
      this.customerName = null;
      this.erpCustomerNumber = null;
      this.customerShortName = null;
      this.dunningCustomerRuleMappingData = [];
      this.parent_value_set_id = null;
      this.totalRows = null;
    },
    rowSelected(item) {
      this.showAddCustomerRuleMappingModal = item;
      this.dunningRuleMappingDetail = item;
      this.showHideCustomerRuleMappingModal(true);
    },
    showHideCustomerRuleMappingModal(flag) {
      this.showAddCustomerRuleMappingModal = flag;
      if (!flag) {
        this.getDunningCustomerRuleMappingList();
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
