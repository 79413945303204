import appStrings from '@/app/utility/string.utility';
export default {
  name: 'AddCustomerRuleMapping',
  components: {},
  props: ['dunningRuleMappingDetail'],
  data() {
    return {
      isSuccess: false,
      showAlert: false,
      responseMsg: null,
      editMode: false,
      customerName: null,
      customerNumber: null,
      customerId: null,
      mappingIndex: null,
      defaultMapping: null,
      defaultMappingId: null,
      assetIndex: null,
      setTimeVsetCode: null,
      vsetCode: null,
      customerMappingId: 0,

      parent_value_set_id: null,
      showValueSetModal: false,
      showAddDunningMasterModal: false,
      payload: null,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      loader: false,
      dunningAddCustomerRuleMappingData: [
        {
          rule_name: null,
          customer_id: null,
          customer_rule_mapping_id: 0,
          dunning_letter: null,
          dunning_letter_path: null,
          is_activated: true,
          rule_id: null
        }
      ],
      dunningAddCustomerRuleMappingFields: [
        {
          key: 'rule_name'
        },
        {
          key: 'is_activated',
          label: 'Active'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    if (this.dunningRuleMappingDetail) {
      this.customerId = this.dunningRuleMappingDetail.customer_id;
      this.customerName = this.dunningRuleMappingDetail.customer_name;
      this.getDunningCustomerRuleMappingDataById();
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditCustomerRuleMappinngData();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getDunningCustomerRuleMappingDataById() {
      this.loader = true;
      this.$store
        .dispatch(
          'dunningAdmin/getDunningCustomerRuleMappingDataById',
          this.customerId
        )
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.dunningAddCustomerRuleMappingData = result.page;
            this.dunningAddCustomerRuleMappingData.map(data => {
              data.is_activated = data.is_activated === 'Y' ? true : false;
              return data;
            });
          }
        })

        .catch(() => {
          this.loader = false;
        });
    },
    addEditCustomerRuleMappinngData() {
      const custDetails = this.dunningAddCustomerRuleMappingData.map(elem => {
        return {
          customer_rule_mapping_id: elem.customer_rule_mapping_id,
          is_activated: elem.is_activated === true ? 'Y' : 'N',
          rule_id: elem.rule_id
        };
      });

      const payload = {
        customer_id: this.customerId,
        data: custDetails ? custDetails : null
      };
      this.loader = true;
      this.$store
        .dispatch('dunningAdmin/addEditCustomerRuleMappinngData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },

    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.DUNNING_RULE_LIST:
          if (this.mappingIndex >= 0) {
            this.dunningAddCustomerRuleMappingData[
              this.mappingIndex
            ].rule_name = item.value_code;
            this.dunningAddCustomerRuleMappingData[this.mappingIndex].rule_id =
              item.value_set_dtl_id;
            this.mappingIndex = null;
          }
      }
    },
    openValueSetModal(vsetCode) {
      // this.chooseParentVsetId(vsetCode);
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    chooseValueset(name, index) {
      this.mappingIndex = index;
      switch (name) {
        case 'rule_name':
          this.openValueSetModal(appStrings.VALUESETTYPE.DUNNING_RULE_LIST);
      }
    },
    addNewRow() {
      if (this.editMode) {
        this.dunningAddCustomerRuleMappingData.push({
          rule_name: null,
          is_activated: true,
          customer_id: null,
          customer_rule_mapping_id: 0,
          dunning_letter: null,
          dunning_letter_path: null,
          rule_id: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.dunningAddCustomerRuleMappingData.splice(index, 1);
      }
    },
    clearVsetValues(vsetCode, index) {
      if(vsetCode === 'rule_name'){
            this.dunningAddCustomerRuleMappingData[index].rule_name = null;
            this.dunningAddCustomerRuleMappingData[index].rule_id = null;
          }
       },
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
